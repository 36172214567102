import React, { useState, useRef } from 'react';
import '@mui/material/Paper';
import { StyledTableCellHead, StyledTableContainer } from '../DefaultTable/DefaultTable';
import BudgetListRow from '../BudgetListRow/BudgetListRow';
import { TodayRounded } from '@mui/icons-material';
import {
    Table,
    IconButton,
    TableBody,
    TableHead,
    TableRow
} from '@mui/material';
import DateRangePicker from '../DateRangePicker/DateRangePicker';

const BudgetsTable = ({ budgets, filteredBudgets, setFilteredBudgets }) => {
    const [openCalendar, setOpenCalendar] = useState(false)
    const dateButtonRef = useRef()

    const handleToggleCalendar = () => {
        setOpenCalendar((prevState) => !prevState)
    }

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCellHead variant='outline'>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Nome</p>
                            </div>
                        </StyledTableCellHead >
                        <StyledTableCellHead variant='outline'  sx={{ padding: '5px 20px !important', width: '30%' }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Data de criação</p>
                                <IconButton onClick={handleToggleCalendar} ref={dateButtonRef} sx={{ color: '#fff' }}>
                                    <TodayRounded fontSize='small' />
                                </IconButton>
                                <DateRangePicker
                                    handleClose={(e) => { setOpenCalendar(false) }}
                                    isOpen={openCalendar}
                                    _ref={dateButtonRef}
                                    initialList={budgets}
                                    setFiltered={setFilteredBudgets}
                                />
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredBudgets.map((budget, i) => (
                        <BudgetListRow budget={budget} key={i} />
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default BudgetsTable;
