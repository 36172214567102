import React from "react";
import styled from "styled-components";
import { Instagram, LinkedIn } from '@mui/icons-material';

const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    a{
        text-decoration: none;
        color: black;
    }
    
    .social-icons{
        display: flex;
        gap: 10px;
    }
`
const Footer = () => {

    return (
        <FooterContainer>
            <a
                target="_blank"
                href="https://landscape.productions/"
                className="title-small">
                Replica faz parte <br /> de <u>Landscape</u>
            </a>

            <div className="social-icons">
                <a
                    target="_blank"
                    href="https://www.instagram.com/byreplica.io/">
                    <Instagram />
                </a>
                <a
                    target="_blank"
                    href="https://www.linkedin.com/company/byreplica/about/"><LinkedIn /></a>
            </div>
        </FooterContainer>
    )
}

export default Footer