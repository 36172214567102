export const isExpired = (token) => {
    if (token) {
        var isExpired = false;
        var dateNow = new Date();

        if (token.exp * 1000 < dateNow.getTime())
            isExpired = true;

        return isExpired
    } else {
        return false
    }
}
export const checkToken = () => {
    if (isExpired(window.localStorage.getItem("jwt__token")) ||
        window.localStorage.getItem("jwt__token") === null ||
        window.localStorage.getItem("jwt__token") === ""
    ) {
        window.location.href = "/"
    }
}

export const checkPasswordRequirements = (requirements) => {
    const errors = [];

    if (!requirements.hasLowerCase) {
        errors.push("uma letra minúscula");
    }
    if (!requirements.hasUpperCase) {
        errors.push("uma letra maiúscula");
    }
    if (!requirements.hasNumbers) {
        errors.push("um número");
    }
    if (!requirements.hasSpecialChars) {
        errors.push("um caractere especial (ex: !@#$%)");
    }
    if (!requirements.isLongEnough) {
        errors.push("8 caracteres");
    }

    return errors;
}