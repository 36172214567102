import { Container } from "./style";
import React from "react";
import { ReactComponent as RIcon } from '../../icons/r-vector.svg';
import { Instagram, LinkedIn } from '@mui/icons-material';

const MobileAlert = () => {

    return (
        <Container>
            <RIcon className="r-icon" />
            <div className="link-container">
                <p className="title-large">Nossa plataforma funciona <br /> melhor no computador.</p>
                <div>
                    <span className="title-large">Acesse: </span> <br />
                    <a className="link title-large" href="https://client.byreplica.io/">client.byreplica.io</a>
                </div>
            </div>
            <div className="social-icons">
                <a
                    target="_blank"
                    href="https://www.instagram.com/byreplica.io/">
                    <Instagram />
                </a>

                <a target="_blank" href="https://www.linkedin.com/company/byreplica/about/">
                    <LinkedIn fontSize="large" />
                </a>
            </div>
        </Container>
    )
}

export default MobileAlert;