import React, { useState, useEffect } from 'react';
import { BudgetContainer, Details, HeaderSection } from './style'
import QuotationSummary from '../QuotationSummary/QuotationSummary'
import BudgetTerms from '../BudgetTerms/BudgetTerms'
import { VerticalAlignBottom } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { CircularProgress, Backdrop } from '@mui/material';

const BudgetBody = ({ budget }) => {
    const [version, setVersion] = useState(null)
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleGetVersions = () => {
        var getVersionsByBudgetBase = '/Budget/GetBudgetVersionsByBudget'
        var formData = { baseBudgetId: budget.baseBudgetId }

        api.post(getVersionsByBudgetBase, formData).then((response) => {
            return response.data;
        }).then((data) => {
            data.forEach((item) => {
                if (item.isActive === true) {
                    setVersion(item)
                }
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleDownloadPDFVersion = () => {
        setOpenBackdrop(true);
        var versionPDFURL = '/Budget/DownloadBudgetVersionPdf';
        var formData = {
            jobId: budget.jobId,
            budgetId: version.budgetVersionId
        }

        api.post(versionPDFURL, formData, { responseType: 'blob' })
            .then((response) => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `${budget.companyName.replace(" ", "")}-orcamento-V${version.version}.pdf`;

                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(fileURL);
                link.remove();
                setOpenBackdrop(false);
            }).catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (budget) {
            handleGetVersions();
        }
    }, [budget])

    return (
        <>{version &&
            <BudgetContainer variant='outlined'>
                <HeaderSection>
                    <div className='title-container'>
                        <p className='title-small'>Versão {version.version}{"   "}|{"   "}{version.createdAt}</p>
                    </div>
                    <Link className='title-small' onClick={handleDownloadPDFVersion}>
                        Baixar carta
                        <VerticalAlignBottom fontSize='small' />
                    </Link>
                </HeaderSection>

                <Details>
                    <QuotationSummary summary={version.budgetResume} due={version.due} />
                    <BudgetTerms terms={version.terms} />
                </Details>


                {openBackdrop &&
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={openBackdrop}
                        onClick={() => { setOpenBackdrop(false) }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>}
            </BudgetContainer>}
        </>
    );
};

export default BudgetBody;
