import { useState, useRef, useEffect } from "react";
import { IconButton } from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";
import FilterStatusPopover from "../FilterPopover/FilterStatusPopover";

const FilterButton = ({ jobs, filteredJobs, setFilteredJobs  }) => {
    const anchorEl = useRef(null)
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [openFilter, setOpenFilter] = useState(false)

    const handleClose = () => {
        setOpenFilter(false)
    };

    useEffect(() => {
        if (jobs) {
            filterJobsByStatus()
        }
    }, [selectedStatus])

    const filterJobsByStatus = () => {

        const filteredList = jobs?.filter((item) =>
            selectedStatus.includes(item.status))

        if (selectedStatus.length === 0) {
            setFilteredJobs(jobs);
        } else {
            setFilteredJobs(filteredList)
        }
    }

    return (<>
        <IconButton ref={anchorEl} onClick={(e) => { setOpenFilter(true) }} sx={{ color: '#fff' }}>
            <FilterAltOutlined fontSize='small' />
        </IconButton>
        <FilterStatusPopover
            openFilter={openFilter} anchorEl={anchorEl}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            onClose={handleClose}
        />
    </>
    )
}

export default FilterButton;