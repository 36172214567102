import React, { useEffect, useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, LoginPaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"
import { jwtDecode } from "jwt-decode";
import { isExpired } from "../../utils/methods";
import api from "../../services/api";
import Backdrop from "../Backdrop/Backdrop"

const LoginForm = () => {
    const [authForm, setAuthForm] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);

    var decodedToken;

    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }

    const handleAuthentication = (e) => {
        e.preventDefault()
        setLoading(true);

        var loginURL = '/Authentication/Login'

        api.post(loginURL, authForm)
            .then((response) => {
                return response.data
            })
            .then((data) => {
                if (data) {
                    const { company } = jwtDecode(data);
                    if (company === "0") {
                        window.location.href = '/login';
                    } else {
                        setLoading(false)
                        window.localStorage.setItem('jwt__token', data);
                        window.location.href = '/';
                    }
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }


    useEffect(() => {
        if (window.localStorage.getItem("jwt__token") !== "" && window.localStorage.getItem("jwt__token") !== null) {
            decodedToken = jwtDecode(window.localStorage.getItem("jwt__token"));

            if (!isExpired(decodedToken)) {
                const { company, email } = decodedToken;
                var userObj = { company: company, email: email }
                window.localStorage.setItem('user__obj', JSON.stringify(userObj))
                window.location.href = '/budgets'
            }
        }
    }, [])

    return (
        <LoginPaper elevation={2} >
            <p className="headline-small">Faça seu login</p>
            <AuthForm onSubmit={handleAuthentication}>
                <TextFieldComp
                    name={"email"}
                    label={"E-mail"}
                    value={authForm.email}
                    onChange={handleCredentials}
                    type={"email"}
                    fullWidth={true}
                    placeholder="Digite seu e-mail aqui"
                    required
                />
                <TextFieldComp
                    name={"password"}
                    label={"Senha"}
                    type={"password"}
                    value={authForm.password}
                    onChange={handleCredentials}
                    fullWidth={true}
                    placeholder="Digite sua senha aqui"
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>
            {loading &&
                <Backdrop open={loading} />}
        </LoginPaper>
    )
}

export default LoginForm;