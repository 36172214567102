import React, {
    useState, useEffect, useRef
} from 'react';
import '@mui/material/Paper';
import { StyledTableCellHead, StyledTableCell, StyledTableRow, StyledTableContainer } from '../DefaultTable/DefaultTable';
import Status from '../Status/Status';
import { useNavigate } from 'react-router-dom';
import FilterButton from "../FilterButton/FilterButton"
import { Search, TodayRounded, Close } from '@mui/icons-material';

import {
    Table,
    IconButton, TableBody,
    TableHead,
    TableRow,
} from '@mui/material';
import DateRangePicker from "../DateRangePicker/DateRangePicker"

const JobTable = ({ jobs, filteredJobs, setFilteredJobs }) => {
    const navigate = useNavigate();
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const dateButtonRef = useRef();

    const handleOpenJob = (id) => {
        navigate(id.toString());
    }


    const handleToggleCalendar = () => {
        setOpenDatePicker((prevState) => !prevState)
    }

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCellHead variant="no-outline" sx={{ padding: '5px 20px !important' }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Jobs</p>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important', width: '30%' }} className={"title-medium"}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Data de criação</p>
                                <IconButton ref={dateButtonRef} onClick={handleToggleCalendar} sx={{ color: '#fff' }}>
                                    {openDatePicker ?
                                        <Close fontSize="small" /> :
                                        <TodayRounded fontSize="small" />
                                    }
                                </IconButton>
                                <DateRangePicker
                                    setFiltered={setFilteredJobs}
                                    initialList={jobs}
                                    _ref={dateButtonRef}
                                    handleClose={(e) => { setOpenDatePicker(false) }}
                                    isOpen={openDatePicker}
                                />
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead variant="outline" sx={{ padding: '5px 20px !important', width: "30%" }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Status</p>
                                <FilterButton jobs={jobs} filteredJobs={filteredJobs} setFilteredJobs={setFilteredJobs} />
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredJobs.map((job) => (
                        <StyledTableRow onClick={() => { handleOpenJob(job.id) }} key={job.name}>
                            <StyledTableCell variant="no-outline" className='body-large'>{job.name}</StyledTableCell>
                            <StyledTableCell variant="no-outline" className='body-large'>{job.createdAt}</StyledTableCell>
                            <StyledTableCell variant="no-outline" className='body-large'>
                                <Status status={job.status}>{job.status}</Status>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default JobTable;
