import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom"
import CustomButton from "../../components/CustomButton/CustomButton";
import api from "../../services/api";
import { checkToken } from "../../utils/methods";
import { Check, VerticalAlignBottom } from '@mui/icons-material';
import axios from "axios";

const PreviewPage = () => {
    const [job, setJob] = useState(null);
    const { id } = useParams();
    const [capeData, setCapeData] = useState()

    useEffect(() => {
        checkToken();
        getJobInfos()
        getCapeData();
    }, [])

    const handleApprove = () => {
        var allCompaniesURL = `/Job/${job.id}/UpdateJobStatus/`
        var formData = {
            "statusId": 5
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Atualização feita com sucesso!') {
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleDownload = () => {
        axios
            .get(capeData.downloadLink, {
                responseType: 'blob',
            })
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                var filename = `${job.name}-${job.companyName.replace(" ", "")}-replica.zip`
                filename = filename.toLowerCase();
                link.setAttribute('download', filename);

                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    }

    const getCapeData = () => {
        axios.get("https://storage.bycape.io/account-221/fixed/DownloadsPreviews_Feed.json")
            .then(response => response.data)
            .then(data => {
                const campaign = data.filter((c) => c.replicaPortalId === id)
                if (campaign.length > 0) {
                    setCapeData(campaign[0])
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    const getJobInfos = () => {
        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": Number(id)
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJob(data)
        }).catch((error) => {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        })
    }

    return (
        <Container>
            <PageHeader>
                <Link to={"/jobs"} className="title-small">
                    <ArrowBack /> Voltar
                </Link>
                {job?.status === "Replicating" &&
                    <CustomButton endIcon={<Check />} variant="contained" onClick={handleApprove}>
                        <span className='title-small'> Aprovar job</span>
                    </CustomButton>}
                {job?.status === "Delivered" &&
                    <CustomButton endIcon={<VerticalAlignBottom />} variant="outlined" onClick={handleDownload}>
                        <span className='title-small'> Baixar peças </span>
                    </CustomButton>}
            </PageHeader>

            <iframe style={{ border: "none" }}
                width="100%"
                height="100%"
                src={capeData?.previewLink} allowfullscreen>
            </iframe>
        </Container>
    )
}

export default PreviewPage;